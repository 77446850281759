import React, { Component } from "react"
import { graphql } from "gatsby"

import IndexLayout from '../layouts'
import ReactMarkdown from "react-markdown"
import gfm from 'remark-gfm'

const InformationPage = ({ data }) => {
  return (
    <IndexLayout>
      <section className="hero is-primary">
        <div className="hero-body">
          <div className="container ">
            <h1 className="title has-text-centered">
              {data.allStrapiInfo.nodes[0].Titre}
            </h1>
          </div>
        </div>
      </section>
      <section className="section content">
      <ReactMarkdown plugins={[gfm]} children={data.allStrapiInfo.nodes[0].Contenu} />
      </section>
    </IndexLayout>
  )
}

export default InformationPage


export const pageQuery = graphql`
query InformationQuery($id: Int) {
  allStrapiInfo(filter: {strapiId: {eq: $id}}) {
    nodes {
      Contenu
      Slug
      Titre
      strapiId
    }
  }
}
`